class LibraryManager {

    libraries_loaded = {};
    after_loaded = [];

    waitFor( library ) {

        if ( this.libraries_loaded[ library ] ) {

            return;
        }

        console.log( 'Waiting for `' + library + '` to load.' );
        this.libraries_loaded[ library ] = false;
    }

    checkLibraries() {

        let _this = this;

        let call_stack = [];

        this.after_loaded = this.after_loaded.filter( function ( after_loaded ) {

            let all_loaded = true;

            for ( let x in after_loaded.libraries ) {

                let library = after_loaded.libraries[ x ];
                if ( !_this.libraries_loaded[ library ] ) {

                    all_loaded = false;
                    break;
                }
            }

            if ( all_loaded ) {

                call_stack.push( after_loaded.callback );
            }

            return !all_loaded;
        } );

        for ( let i in call_stack ) {

            call_stack[ i ].call();
        }
    }

    setLoaded( library ) {

        console.log( library + ' is loaded.' );
        this.libraries_loaded[ library ] = true;

        this.checkLibraries();
    }

    after( libraries, callback ) {

        this.after_loaded.push( { libraries: libraries, callback: callback } );
        this.checkLibraries();
    }

    isLoaded( library ) {

        return !!this.libraries_loaded[ library ];
    }
}